import React from "react"
import BannerAnim, { Element } from "rc-banner-anim"
import { useStaticQuery, graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import TweenOne from "rc-tween-one"
import Img from "gatsby-image"
import "rc-banner-anim/assets/index.css"
import { Container } from "react-bootstrap"

export default function mobileBanner() {
  const { BgElement } = Element
  const data = useStaticQuery(graphql`
    query {
      manage_1: file(relativePath: { eq: "manage_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      manage_2: file(relativePath: { eq: "manage_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      manage_3: file(relativePath: { eq: "manage_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <Container>
      <BannerAnim>
        <Element prefixCls="banner-user-elem" key="0">
          <BgElement
            key="bg"
            className="bg"
            css={css`
              box-shadow: 0px 1px 10px 1px rgba(232, 222, 220, 1);
              border-radius: 20px;
            `}
          >
            <Img
              fluid={data.manage_3.childImageSharp.fluid}
              alt=""
              css={css`
                width: 100%;
              `}
            />
          </BgElement>
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 0, type: "from" }}
            css={css`
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
            `}
          >
            软件系统开发
          </TweenOne>
          <TweenOne
            className="banner-user-text"
            animation={{ y: 30, opacity: 0, type: "from" }}
            css={css`
              font-family: PingFangSC-Medium, PingFang SC;
            `}
          >
            专注于软件、应用的研究，丰富的开发经验和团队建设，成熟和稳定的定制开发解决方案
          </TweenOne>
          <TweenOne
            className="banner-user-button"
            animation={{ y: 30, opacity: 0, type: "from" }}
          >
            <Link
              to="/operation/"
              state={{ option: 1 }}
              css={css`
                text-decoration: none;
              `}
            >
              <div
                css={css`
                  border-radius: 26px;
                  border: 2px solid rgba(222, 228, 235, 1);
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(106, 102, 100, 1);
                `}
                className="button"
              >
                查看更多
              </div>
            </Link>
          </TweenOne>
        </Element>
        <Element prefixCls="banner-user-elem" key="2">
          <BgElement
            key="bg"
            className="bg"
            css={css`
              box-shadow: 0px 1px 10px 1px rgba(232, 222, 220, 1);
              border-radius: 20px;
            `}
          >
            <Img
              fluid={data.manage_2.childImageSharp.fluid}
              alt=""
              css={css`
                width: 100%;
              `}
            />
          </BgElement>
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 0, type: "from" }}
            css={css`
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
            `}
          >
            数据采集和分析
          </TweenOne>
          <TweenOne
            className="banner-user-text"
            animation={{ y: 30, opacity: 0, type: "from" }}
            css={css`
              font-family: PingFangSC-Medium, PingFang SC;
            `}
          >
            以大数据分析为核心，基于数据处理、数据分析于一体的技术支持服务。
          </TweenOne>
          <TweenOne
            className="banner-user-button"
            animation={{ y: 30, opacity: 0, type: "from" }}
          >
            <Link
              to="/operation/"
              state={{ option: 2 }}
              css={css`
                text-decoration: none;
              `}
            >
              <div
                css={css`
                  border-radius: 26px;
                  border: 2px solid rgba(222, 228, 235, 1);
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(106, 102, 100, 1);
                `}
                className="button"
              >
                查看更多
              </div>
            </Link>
          </TweenOne>
        </Element>
        <Element prefixCls="banner-user-elem" key="1">
          <BgElement
            key="bg"
            className="bg"
            css={css`
              box-shadow: 0px 1px 10px 1px rgba(232, 222, 220, 1);
              border-radius: 20px;
            `}
          >
            <Img
              fluid={data.manage_1.childImageSharp.fluid}
              alt=""
              css={css`
                width: 100%;
              `}
            />
          </BgElement>
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 0, type: "from" }}
            css={css`
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
            `}
          >
            短信和银行卡身份验证
          </TweenOne>
          <TweenOne
            className="banner-user-text"
            animation={{ y: 30, opacity: 0, type: "from" }}
            css={css`
              font-family: PingFangSC-Medium, PingFang SC;
            `}
          >
            通过认证、审核、风控、客服“四位一体”的认证流程，为广大客户提供优质
            高效的互联网平台主体资质认证审核服务。
          </TweenOne>
          <TweenOne
            className="banner-user-button"
            animation={{ y: 30, opacity: 0, type: "from" }}
          >
            <Link
              to="/operation/"
              state={{ option: 3 }}
              css={css`
                text-decoration: none;
              `}
            >
              <div
                css={css`
                  border-radius: 26px;
                  border: 2px solid rgba(222, 228, 235, 1);
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(106, 102, 100, 1);
                `}
                className="button"
              >
                查看更多
              </div>
            </Link>
          </TweenOne>
        </Element>
      </BannerAnim>
    </Container>
  )
}
