import React from 'react'
import BannerAnim, { Element } from 'rc-banner-anim'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import TweenOne from 'rc-tween-one'
import Img from 'gatsby-image'
import 'rc-banner-anim/assets/index.css'
import { Container } from 'react-bootstrap'

export default function mobileBanner() {
  const { BgElement } = Element
  const data = useStaticQuery(graphql`
    query {
      banner_1: file(relativePath: { eq: "1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      banner_2: file(relativePath: { eq: "2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      banner_3: file(relativePath: { eq: "3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <Container>
      <BannerAnim>
        <Element prefixCls="banner-user-elem" key="0">
          <BgElement key="bg" className="bg">
            {' '}
            <Img
              fluid={data.banner_2.childImageSharp.fluid}
              alt=""
              css={css`
                width: 100%;
              `}
            />
          </BgElement>
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 0, type: 'from' }}
            css={css`
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
            `}
          >
            软件系统开发
          </TweenOne>
        </Element>
        <Element prefixCls="banner-user-elem" key="1">
          <BgElement key="bg" className="bg">
            {' '}
            <Img
              fluid={data.banner_1.childImageSharp.fluid}
              alt=""
              css={css`
                width: 100%;
              `}
            />
          </BgElement>
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 0, type: 'from' }}
            css={css`
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
            `}
          >
            短信和银行卡身份验证
          </TweenOne>
        </Element>
        <Element prefixCls="banner-user-elem" key="2">
          <BgElement key="bg" className="bg">
            {' '}
            <Img
              fluid={data.banner_3.childImageSharp.fluid}
              alt=""
              css={css`
                width: 100%;
              `}
            />
          </BgElement>
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 0, type: 'from' }}
            css={css`
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
            `}
          >
            数据采集和分析
          </TweenOne>
        </Element>
      </BannerAnim>
    </Container>
  )
}
