import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Nav from '../components/nav'
import Banner from '../components/home/banner'
import Strength from '../components/home/strength'
import Business from '../components/home/business'
import AboutUs from '../components/home/aboutUs'
import Partner from '../components/home/partner'
import Footer from '../components/footer'
import SEO from '../components/seo'

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      backgroundHome: file(relativePath: { eq: "backgroundHome.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <div>
      <SEO title="首页"  />
      {/* 背景图片 */}
      <div
        className="homeNav"
        css={css`
          width: 100%;
          background: url(${data.backgroundHome.childImageSharp.fluid.src})
            center center;
        `}
      >
        <Nav />
        <Banner />
      </div>
      {/* 公司优势  */}
      <Strength />
      {/* 核心业务 */}
      <Business />
      {/* 关于我们 */}
      <AboutUs />
      {/* 合作伙伴 */}
      <Partner />
      {/* 页脚 */}
      <div className="homeFooter">
        <Footer />
      </div>
    </div>
  )
}
