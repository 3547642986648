import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { Container, Row, Col } from 'react-bootstrap'
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      strength_1_1: file(relativePath: { eq: "strength_1_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strength_1_2: file(relativePath: { eq: "strength_1_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strength_1_3: file(relativePath: { eq: "strength_1_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strength_2_1: file(relativePath: { eq: "strength_2_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strength_2_2: file(relativePath: { eq: "strength_2_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strength_2_3: file(relativePath: { eq: "strength_2_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strengthw_1_1: file(relativePath: { eq: "strengthw_1_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strengthw_1_2: file(relativePath: { eq: "strengthw_1_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strengthw_1_3: file(relativePath: { eq: "strengthw_1_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strengthw_2_1: file(relativePath: { eq: "strengthw_2_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strengthw_2_2: file(relativePath: { eq: "strengthw_2_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      strengthw_2_3: file(relativePath: { eq: "strengthw_2_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div className="strength">
      <Container
        css={css`
          text-align: center;
        `}
      >
        <div className="title">公司优势</div>
        <Row
          css={css`
            margin-bottom: 55px;
          `}
        >
          <Col
            xl="8"
            lg="8"
            md="12"
            sm="12"
            xs="12"
            css={css`
              padding: 0 2% 5%;
            `}
          >
            <div
              className="step"
              css={css`
                border-radius: 20px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 1);
                :hover {
                  background: linear-gradient(
                    90deg,
                    rgba(246, 179, 82, 1) 0%,
                    rgba(255, 147, 115, 1) 100%
                  );
                  box-shadow: 0px 0px 30px 3px rgba(255, 147, 115, 1);
                  color: rgba(255, 255, 255, 1);
                  .img .gatsby-image-wrapper{
                      opacity:0
                  }
                }
              `}
            >
              <div
                css={css`
                  background: url(${data.strengthw_1_1.childImageSharp.fluid
                  .src})
                    center center;
                  background-size: cover;
                  margin: 0 auto;
                `}
                className="img"
              >
                 <Img
                    fluid={data.strength_1_1.childImageSharp.fluid}
                    alt=""
                    css={css`
                      width: 100%;
                    `}
                  />
              </div>

              <div
                css={css`
                  font-family: PingFang-SC-Bold, PingFang-SC;
                  font-weight: bold;
                `}
                className="subtitle"
              >
                实力强大
              </div>
              <div
                css={css`
                  height: 20px;
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  padding-top: 6px;
                `}
                className="content"
              >
                强大的实力、优秀的合作伙伴资源
              </div>
            </div>
          </Col>
          <Col
            xl="8"
            lg="8"
            md="12"
            sm="12"
            xs="12"
            css={css`
              padding: 0 2% 5%;
            `}
          >
            <div
              className="step"
              css={css`
                border-radius: 20px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 1);
                :hover {
                  background: linear-gradient(
                    90deg,
                    rgba(246, 179, 82, 1) 0%,
                    rgba(255, 147, 115, 1) 100%
                  );
                  box-shadow: 0px 0px 30px 3px rgba(255, 147, 115, 1);
                  color: rgba(255, 255, 255, 1);
                  .img .gatsby-image-wrapper{
                    opacity:0
                }
                }
              `}
            >
              <div
                css={css`
                  background: url(${data.strengthw_1_2.childImageSharp.fluid
                  .src})
                    center center;
                  background-size: cover;
                  margin: 0 auto;
                `}
                className="img"
              >
               <Img
                    fluid={data.strength_1_2.childImageSharp.fluid}
                    alt=""
                    css={css`
                      width: 100%;
                    `}
                  />
              </div>

              <div
                css={css`
                  font-family: PingFang-SC-Bold, PingFang-SC;
                  font-weight: bold;
                `}
                className="subtitle"
              >
                技术领先
              </div>
              <div
                css={css`
                  height: 20px;
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  padding-top: 6px;
                `}
                className="content"
              >
                数据采集技术领先，处理经验丰富，数据安全
              </div>
            </div>
          </Col>
          <Col
            xl="8"
            lg="8"
            md="12"
            sm="12"
            xs="12"
            css={css`
              padding: 0 2% 5%;
            `}
          >
            <div
              className="step"
              css={css`
                border-radius: 20px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 1);
                :hover {
                  background: linear-gradient(
                    90deg,
                    rgba(246, 179, 82, 1) 0%,
                    rgba(255, 147, 115, 1) 100%
                  );
                  box-shadow: 0px 0px 30px 3px rgba(255, 147, 115, 1);
                  color: rgba(255, 255, 255, 1);
                  .img .gatsby-image-wrapper{
                    opacity:0
                }
                }
              `}
            >
              <div
                css={css`
                  background: url(${data.strengthw_1_3.childImageSharp.fluid
                  .src})
                    center center;
                  background-size: cover;
                  margin: 0 auto;
                `}
                className="img"
              >
               <Img
                    fluid={data.strength_1_3.childImageSharp.fluid}
                    alt=""
                    css={css`
                      width: 100%;
                    `}
                  />
              </div>

              <div
                css={css`
                  font-family: PingFang-SC-Bold, PingFang-SC;
                  font-weight: bold;
                `}
                className="subtitle"
              >
                渠道广泛
              </div>
              <div
                css={css`
                  height: 20px;
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  padding-top: 6px;
                `}
                className="content"
              >
                大数据、征信渠道广泛、合法
              </div>
            </div>
          </Col>
          <Col
            xl="8"
            lg="8"
            md="12"
            sm="12"
            xs="12"
            css={css`
              padding: 0 2% 5%;
            `}
          >
            <div
              className="step"
              css={css`
                border-radius: 20px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 1);
                :hover {
                  background: linear-gradient(
                    90deg,
                    rgba(246, 179, 82, 1) 0%,
                    rgba(255, 147, 115, 1) 100%
                  );
                  box-shadow: 0px 0px 30px 3px rgba(255, 147, 115, 1);
                  color: rgba(255, 255, 255, 1);
                  .img .gatsby-image-wrapper{
                    opacity:0
                }
                }
              `}
            >
              <div
                css={css`
                  background: url(${data.strengthw_2_1.childImageSharp.fluid
                  .src})
                    center center;
                  background-size: cover;
                  margin: 0 auto;
                `}
                className="img"
              >
               <Img
                    fluid={data.strength_2_1.childImageSharp.fluid}
                    alt=""
                    css={css`
                      width: 100%;
                    `}
                  />
              </div>

              <div
                css={css`
                  font-family: PingFang-SC-Bold, PingFang-SC;
                  font-weight: bold;
                `}
                className="subtitle"
              >
                丰富经验
              </div>
              <div
                css={css`
                  height: 20px;
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  padding-top: 6px;
                `}
                className="content"
              >
                丰富的实践经验，大量的成功案例
              </div>
            </div>
          </Col>
          <Col
            xl="8"
            lg="8"
            md="12"
            sm="12"
            xs="12"
            css={css`
              padding: 0 2% 5%;
            `}
          >
            <div
              className="step"
              css={css`
                border-radius: 20px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 1);
                :hover {
                  background: linear-gradient(
                    90deg,
                    rgba(246, 179, 82, 1) 0%,
                    rgba(255, 147, 115, 1) 100%
                  );
                  box-shadow: 0px 0px 30px 3px rgba(255, 147, 115, 1);
                  color: rgba(255, 255, 255, 1);
                  .img .gatsby-image-wrapper{
                    opacity:0
                }
                }
              `}
            >
              <div
                css={css`
                  background: url(${data.strengthw_2_2.childImageSharp.fluid
                  .src})
                    center center;
                  background-size: cover;
                  margin: 0 auto;
                `}
                className="img"
              >
               <Img
                    fluid={data.strength_2_2.childImageSharp.fluid}
                    alt=""
                    css={css`
                      width: 100%;
                    `}
                  />
              </div>

              <div
                css={css`
                  font-family: PingFang-SC-Bold, PingFang-SC;
                  font-weight: bold;
                `}
                className="subtitle"
              >
                自主研发
              </div>
              <div
                css={css`
                  height: 20px;
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  padding-top: 6px;
                `}
                className="content"
              >
                自主研发多种数据采集、认证等系统
              </div>
            </div>
          </Col>
          <Col
            xl="8"
            lg="8"
            md="12"
            sm="12"
            xs="12"
            css={css`
              padding: 0 2% 5%;
            `}
          >
            <div
              className="step"
              css={css`
                border-radius: 20px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 1);
                :hover {
                  background: linear-gradient(
                    90deg,
                    rgba(246, 179, 82, 1) 0%,
                    rgba(255, 147, 115, 1) 100%
                  );
                  box-shadow: 0px 0px 30px 3px rgba(255, 147, 115, 1);
                  color: rgba(255, 255, 255, 1);
                  .img .gatsby-image-wrapper{
                    opacity:0
                }
                }
              `}
            >
              <div
                css={css`
                  background: url(${data.strengthw_2_3.childImageSharp.fluid
                  .src})
                    center center;
                  background-size: cover;
                  margin: 0 auto;
                `}
                className="img"
              >
               <Img
                    fluid={data.strength_2_3.childImageSharp.fluid}
                    alt=""
                    css={css`
                      width: 100%;
                    `}
                  />
              </div>

              <div
                css={css`
                  font-family: PingFang-SC-Bold, PingFang-SC;
                  font-weight: bold;
                `}
                className="subtitle"
              >
                专业技术团队
              </div>
              <div
                css={css`
                  height: 20px;
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  padding-top: 6px;
                `}
                className="content"
              >
                强大的专业性技术人才队伍
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
