import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import TweenOne from "rc-tween-one"
import { Container } from "react-bootstrap"
import MobileBanner from "./mobileBanner"
/* eslint no-unused-vars: 0 */
import style from "./styles.css"

export default () => {
  const [note, setNote] = useState("mid")
  const [note_, setNote_] = useState("mid")
  // 设定延迟更改 让轮播图转换时候中间的图与更改的图大小不受max-width影响
  /* eslint no-unused-expressions: 0 */
  const changeNote1 = () => {
    note_ === "mid"
      ? setNote_("right")
      : note_ === "right"
      ? setNote_("left")
      : setNote_("mid")
  }
  const changeNote2 = () => {
    note_ === "mid"
      ? setNote_("left")
      : note_ === "right"
      ? setNote_("mid")
      : setNote_("right")
  }
  const changeBanner = () => {
    note === "mid"
      ? setNote("right")
      : note === "right"
      ? setNote("left")
      : setNote("mid")
    setTimeout(changeNote1, 500)
  }
  const changeBanne2 = () => {
    note === "mid"
      ? setNote("left")
      : note === "right"
      ? setNote("mid")
      : setNote("right")
    setTimeout(changeNote2, 500)
  }
  const data = useStaticQuery(graphql`
    query {
      banner_1: file(relativePath: { eq: "1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      banner_1_s: file(relativePath: { eq: "1.png" }) {
        childImageSharp {
          fluid(maxWidth: 384, maxHeight: 221) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      banner_2: file(relativePath: { eq: "2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      banner_2_s: file(relativePath: { eq: "2.png" }) {
        childImageSharp {
          fluid(maxWidth: 384, maxHeight: 221) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      banner_3: file(relativePath: { eq: "3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      banner_3_s: file(relativePath: { eq: "3.png" }) {
        childImageSharp {
          fluid(maxWidth: 384, maxHeight: 221) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      banner_left: file(relativePath: { eq: "banner_left.png" }) {
        childImageSharp {
          fluid(maxWidth: 60, maxHeight: 40) {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      banner_right: file(relativePath: { eq: "banner_right.png" }) {
        childImageSharp {
          fluid(maxWidth: 60, maxHeight: 40) {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div className="banner">
      <div className="webBanner">
        <Container
          css={css`
            position: relative;
            height: 620px;
            margin-top: 120px;
          `}
        >
          {/* 中间的图 */}
          <TweenOne
            animation={
              note === "mid"
                ? {
                    duration: 500,
                    left: "17.5%",
                    opacity: 1,
                    width: "65%",
                    fontSize: "45px",
                    top: "0px",
                    zIndex: 3,
                  }
                : note === "right"
                ? {
                    duration: 500,
                    left: "68%",
                    opacity: 0.5,
                    width: "32%",
                    fontSize: "22px",
                    top: "129px",
                    zIndex: 1,
                  }
                : {
                    duration: 500,
                    left: "0%",
                    opacity: 0.5,
                    width: "32%",
                    fontSize: "22px",
                    top: "129px",
                    zIndex: 1,
                  }
            }
            paused={false}
            moment={null}
            css={css`
              position: absolute;
              left: 17.5%;
              opacity: 1;
              width: 65%;
              font-size: 45px;
              top: 0px;
              z-index: 4;
            `}
          >
            <div
              css={css`
                margin: 0 auto;
              `}
            >
              <div
                css={css`
                  display: inline-block;
                  font-family: PingFang-SC-Medium, PingFang-SC;
                  font-weight: 500;
                  color: rgba(255, 255, 255, 1);
                  width: 100%;
                  text-align: center;
                  line-height: 63px;
                `}
              >
                软件系统开发
              </div>
              <div
                css={css`
                  padding-top: 47px;
                  width: 100%;
                  text-align: center;
                `}
              >
                <div
                  css={css`
                    background: url(${data.banner_2_s.childImageSharp.fluid
                      .src});
                    width: 100%;
                    background-size: contain;
                  `}
                >
                  <Img
                    fluid={
                      note === "mid"
                        ? data.banner_2.childImageSharp.fluid
                        : data.banner_2_s.childImageSharp.fluid
                    }
                    alt=""
                    css={css`
                      width: 100%;
                      ${note === "mid" || note_ === "mid"
                        ? []
                        : "max-width: 384px;"}
                    `}
                  />
                </div>
              </div>
            </div>
          </TweenOne>
          {/* 左边的图 */}
          <TweenOne
            animation={
              note === "right"
                ? {
                    duration: 500,
                    left: "17.5%",
                    opacity: 1,
                    width: "65%",
                    fontSize: "45px",
                    top: "0px",
                    zIndex: 3,
                  }
                : note === "left"
                ? {
                    duration: 500,
                    left: "68%",
                    opacity: 0.5,
                    width: "32%",
                    fontSize: "22px",
                    top: "129px",
                    zIndex: 1,
                  }
                : {
                    duration: 500,
                    left: "0%",
                    opacity: 0.5,
                    width: "32%",
                    fontSize: "22px",
                    top: "129px",
                    zIndex: 1,
                  }
            }
            paused={false}
            moment={null}
            css={css`
              position: absolute;
              left: 0%;
              opacity: 0.5;
              width: 32%;
              font-size: 22px;
              top: 129px;
              z-index: 1;
            `}
          >
            <div
              css={css`
                margin: 0 auto;
              `}
            >
              <div
                css={css`
                  display: inline-block;
                  font-family: PingFang-SC-Medium, PingFang-SC;
                  font-weight: 500;
                  color: rgba(255, 255, 255, 1);
                  width: 100%;
                  text-align: center;
                  line-height: 63px;
                `}
              >
                短信和银行卡身份验证
              </div>
              <div
                css={css`
                  padding-top: 47px;
                  width: 100%;
                  text-align: center;
                `}
              >
                <div
                  css={css`
                    background: url(${data.banner_1_s.childImageSharp.fluid
                      .src});
                    width: 100%;
                    background-size: contain;
                  `}
                >
                  <Img
                    fluid={
                      note === "right"
                        ? data.banner_1.childImageSharp.fluid
                        : data.banner_1_s.childImageSharp.fluid
                    }
                    alt=""
                    css={css`
                      width: 100%;
                      ${note === "right" || note_ === "right"
                        ? []
                        : "max-width: 384px;"}
                    `}
                  />
                </div>
              </div>
            </div>
          </TweenOne>
          {/* 右边的图 */}
          <TweenOne
            animation={
              note === "left"
                ? {
                    duration: 500,
                    left: "17.5%",
                    opacity: 1,
                    width: "65%",
                    fontSize: "45px",
                    top: "0px",
                    zIndex: 3,
                  }
                : note === "mid"
                ? {
                    duration: 500,
                    left: "68%",
                    opacity: 0.5,
                    width: "32%",
                    fontSize: "22px",
                    top: "129px",
                    zIndex: 1,
                  }
                : {
                    duration: 500,
                    left: "0%",
                    opacity: 0.5,
                    width: "32%",
                    fontSize: "22px",
                    top: "129px",
                    zIndex: 1,
                  }
            }
            paused={false}
            moment={null}
            css={css`
              position: absolute;
              left: 68%;
              opacity: 0.5;
              width: 32%;
              font-size: 22px;
              top: 129px;
              z-index: 1;
            `}
          >
            <div
              css={css`
                margin: 0 auto;
              `}
            >
              <div
                css={css`
                  display: inline-block;
                  font-family: PingFang-SC-Medium, PingFang-SC;
                  font-weight: 500;
                  color: rgba(255, 255, 255, 1);
                  width: 100%;
                  text-align: center;
                  line-height: 63px;
                `}
              >
                数据采集和分析
              </div>
              <div
                css={css`
                  padding-top: 47px;
                  width: 100%;
                  text-align: center;
                `}
              >
                <div
                  css={css`
                    background: url(${data.banner_3_s.childImageSharp.fluid
                      .src});
                    width: 100%;
                    background-size: contain;
                  `}
                >
                  <Img
                    fluid={
                      note === "left"
                        ? data.banner_3.childImageSharp.fluid
                        : data.banner_3_s.childImageSharp.fluid
                    }
                    alt=""
                    css={css`
                      width: 100%;
                      ${note === "left" || note_ === "left"
                        ? []
                        : "max-width: 384px;"}
                    `}
                  />
                </div>
              </div>
            </div>
          </TweenOne>
        </Container>
        <div
          css={css`
            position: absolute;
            top: 453px;
            left: 8%;
            opacity: 0.8;
            z-index: 100;
            cursor: pointer;
            outline: none;
            :hover {
              opacity: 1;
            }
          `}
          onClick={() => changeBanner()}
          onKeyDown={() => {}}
          role="button"
          tabIndex="0"
        >
          <Img
            fluid={data.banner_left.childImageSharp.fluid}
            alt=""
            css={css`
              width: 60px;
            `}
          />
        </div>
        <div
          css={css`
            position: absolute;
            top: 453px;
            left: 88%;
            opacity: 0.8;
            z-index: 100;
            cursor: pointer;
            outline: none;
            :hover {
              opacity: 1;
            }
          `}
          onClick={() => changeBanne2()}
          onKeyDown={() => {}}
          role="button"
          tabIndex="0"
        >
          <Img
            fluid={data.banner_right.childImageSharp.fluid}
            alt=""
            css={css`
              width: 60px;
            `}
          />
        </div>
      </div>
      <div className="mobileBanner">
        <MobileBanner />
      </div>
    </div>
  )
}
