import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"

export default () => {
  const [option, setOption] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [sum, setSum] = useState(3)
  const change = e => {
    if (option + e === 0) {
      setOption(sum)
      return 0
    }
    if (option + e > sum) {
      setOption(1)
      return 0
    }
    setOption(option + e)
    return 0
  }
  const partnerProfile = [
    "PayPal是倍受全球亿万用户追捧的国际贸易支付工具",
    "一体化跨境电商方案",
    "下一代开源的云计算IaaS（基础架构即服务）软件",
  ]

  const data = useStaticQuery(graphql`
    query {
      partner_01: file(relativePath: { eq: "partner_01.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      partner_02: file(relativePath: { eq: "partner_02.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      partner_03: file(relativePath: { eq: "partner_03.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      left: file(relativePath: { eq: "left.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      leftw: file(relativePath: { eq: "leftw.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      right: file(relativePath: { eq: "right.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      rightw: file(relativePath: { eq: "rightw.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  const images = [
   
    <Img
      fluid={data.partner_02.childImageSharp.fluid}
      alt=""
      css={css`
        margin: 0px auto;
        width: 71%;
      `}
    />,
    <Img
      fluid={data.partner_03.childImageSharp.fluid}
      alt=""
      css={css`
        margin: 0px auto;
        width: 100%;
        top: 12%;
      `}
    />, 
    <Img
      fluid={data.partner_01.childImageSharp.fluid}
      alt=""
      css={css`
        margin: 0px auto;
        width: 56%;
      `}
    />,
  ]
  return (
    <div className="partner">
      <Container
        css={css`
          text-align: center;
        `}
      >
        <Row
          css={css`
            width: 100%;
          `}
        >
          <Col
            xl="6"
            lg="6"
            md="24"
            sm="24"
            xs="24"
            css={css`
              padding: 0 20px;
            `}
          >
            <div className="title">
              合作伙伴
              <div
                css={css`
                  display: flex;
                  margin-top: 58px;
                  padding-left: 20%;
                `}
                className="point"
              >
                <div
                  css={css`
                    width: 55px;
                    height: 55px;
                    border-radius: 50px;
                    margin-right: 20px;
                    cursor: pointer;
                    outline: none;
                    :hover {
                      background: linear-gradient(
                        90deg,
                        rgba(246, 179, 82, 1) 0%,
                        rgba(255, 147, 115, 1) 100%
                      );
                      box-shadow: 0px 2px 10px 5px rgba(232, 222, 220, 0.5);
                      border-radius: 50px;
                      div:first-of-type {
                        background: url(${data.leftw.childImageSharp.fluid.src})
                          center center;
                        background-size: cover;
                      }
                    }
                  `}
                  onClick={() => change(1)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <div
                    css={css`
                      background: url(${data.left.childImageSharp.fluid.src})
                        center center;
                      background-size: cover;
                      width: 20px;
                      height: 20px;
                      margin: 0 auto;
                      margin-top: 18px;
                    `}
                  />
                </div>
                <div
                  css={css`
                    width: 55px;
                    height: 55px;
                    cursor: pointer;
                    outline: none;
                    :hover {
                      background: linear-gradient(
                        90deg,
                        rgba(246, 179, 82, 1) 0%,
                        rgba(255, 147, 115, 1) 100%
                      );
                      box-shadow: 0px 2px 10px 5px rgba(232, 222, 220, 0.5);
                      border-radius: 50px;
                      div:first-of-type {
                        background: url(${data.rightw.childImageSharp.fluid
                            .src})
                          center center;
                        background-size: cover;
                      }
                    }
                  `}
                  onClick={() => change(-1)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <div
                    css={css`
                      background: url(${data.right.childImageSharp.fluid.src})
                        center center;
                      background-size: cover;
                      width: 20px;
                      height: 20px;
                      margin: 0 auto;
                      margin-top: 18px;
                    `}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xl="6" lg="6" md="8" sm="8" xs="8">
            <div className="content">
              <div className="img">{images[option - 1]}</div>
              <div> {partnerProfile[option - 1]}</div>
            </div>
          </Col>
          <Col xl="6" lg="6" md="8" sm="8" xs="8">
            <div className="content">
              <div className="img">
                {option + 1 <= sum ? images[option] : images[0]}
              </div>
              <div>
                {option + 1 <= sum ? partnerProfile[option] : partnerProfile[0]}
              </div>
            </div>
          </Col>
          <Col xl="6" lg="6" md="8" sm="8" xs="8">
            <div className="content">
              <div className="img">
                {option + 2 <= sum
                  ? images[option + 1]
                  : option + 1 <= sum
                  ? images[0]
                  : images[1]}
              </div>
              <div>
                {option + 2 <= sum
                  ? partnerProfile[option + 1]
                  : option + 1 <= sum
                  ? partnerProfile[0]
                  : partnerProfile[1]}
              </div>
            </div>
          </Col>
        </Row>

        <div className="relation">
          <div className="text">联系我们了解更多</div>
          <Link
            to="/about#contactUs"
            css={css`
              text-decoration: none;
            `}
          >
            <div className="button">联系我们</div>
          </Link>
        </div>
      </Container>
    </div>
  )
}
