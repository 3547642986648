import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'
import MobileBusiness from './mobileBusiness'

export default () => {
  const [option, setOption] = useState(1)
  const change = (e) => {
    setOption(e)
  }
  const data = useStaticQuery(graphql`
    query {
      manage_1: file(relativePath: { eq: "manage_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      manage_2: file(relativePath: { eq: "manage_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      manage_3: file(relativePath: { eq: "manage_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div className="business">
      <div>
        <Container
          css={css`
            text-align: center;
          `}
        >
          <div className="title">核心业务</div>
          <div className="webBusiness">
            <Row>
              <Col
                xl="8"
                lg="8"
                md="0"
                sm="0"
                css={css`
                  padding: 0 7% 5%;
                `}
              >
                <div
                  css={css`
                    height: 50px;
                    border: 1px solid rgba(222, 228, 235, 1);
                    border-radius: 26px;
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    line-height: 50px;
                    cursor: pointer;
                    outline: none;
                    ${option === 1
                    ? `
             background:linear-gradient(
              90deg,
              rgba(246, 179, 82, 1) 0%,
              rgba(255, 147, 115, 1) 100%
            );
            color: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 10px 5px rgba(232, 222, 220, 1);`
                    : []};
                  `}
                  onClick={() => change(1)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  软件系统开发
                </div>
              </Col>
              <Col
                xl="8"
                lg="8"
                md="0"
                sm="0"
                css={css`
                  padding: 0 7% 5%;
                `}
              >
                <div
                  css={css`
                    height: 50px;
                    border-radius: 26px;
                    border: 1px solid rgba(222, 228, 235, 1);
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    line-height: 50px;

                    cursor: pointer;
                    outline: none;
                    ${option === 2
                    ? `
             background:linear-gradient(
              90deg,
              rgba(246, 179, 82, 1) 0%,
              rgba(255, 147, 115, 1) 100%
            );
            color: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 10px 5px rgba(232, 222, 220, 1);`
                    : []};
                  `}
                  onClick={() => change(2)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  数据采集和分析
                </div>
              </Col>
              <Col
                xl="8"
                lg="8"
                md="0"
                sm="0"
                css={css`
                  padding: 0 7% 5%;
                `}
              >
                <div
                  css={css`
                    height: 50px;
                    border-radius: 26px;
                    border: 1px solid rgba(222, 228, 235, 1);
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    line-height: 50px;

                    cursor: pointer;
                    outline: none;
                    ${option === 3
                    ? `
             background:linear-gradient(
              90deg,
              rgba(246, 179, 82, 1) 0%,
              rgba(255, 147, 115, 1) 100%
            );
            color: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 10px 5px rgba(232, 222, 220, 1);`
                    : []};
                  `}
                  onClick={() => change(3)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  短信和银行卡身份验证
                </div>
              </Col>
            </Row>
            <Row
              className="content"
              css={css`
                display: flex;
              `}
            >
              <Col
                xl="14"
                lg="14"
                md="24"
                sm="24"
                css={css`
                  padding: 0 7% 0 4%;
                `}
              >
                <div
                  css={css`
                    font-family: PingFang-SC-Bold, PingFang-SC;
                    font-weight: bold;
                    color: rgba(51, 51, 51, 1);
                    text-align: left;
                  `}
                  className="subhead"
                >
                  {option === 1
                    ? '软件系统开发'
                    : option === 2
                      ? '数据采集和分析'
                      : '短信和银行卡身份验证'}
                </div>
                <div
                  css={css`
                    text-align: left;
                    font-family: PingFang-SC-Medium, PingFang-SC;
                    font-weight: 500;
                    color: rgba(51, 51, 51, 1);
                  `}
                  className="text"
                >
                  {option === 1
                    ? '专注于软件、应用的研究，丰富的开发经验和团队建设，成熟和稳定的定制开发解决方案。'
                    : option === 2
                      ? '以大数据分析为核心，基于数据处理、数据分析于一体的技术支持服务。'
                      : '通过认证、审核、风控、客服“四位一体”的认证流程，为广大客户提供优质高效的互联网平台主体资质认证审核服务。 '}
                </div>
                <Link
                  to="/operation/"
                  state={{ option }}
                  css={css`
                    text-decoration: none;
                  `}
                >
                  <div
                    css={css`
                      border-radius: 26px;
                      border: 2px solid rgba(222, 228, 235, 1);
                      font-family: PingFangSC-Semibold, PingFang SC;
                      font-weight: 600;
                      color: rgba(106, 102, 100, 1);
                    `}
                    className="button"
                  >
                    查看更多
                  </div>
                </Link>
              </Col>
              <Col xl="10" lg="10" md="24" sm="24">
                <div
                  css={css`
                    box-shadow: 0px 1px 10px 1px rgba(232, 222, 220, 1);
                    border-radius: 20px;
                  `}
                  className="img"
                >
                  <Img
                    fluid={
                      option === 1
                        ? data.manage_3.childImageSharp.fluid
                        : option === 2
                          ? data.manage_2.childImageSharp.fluid
                          : data.manage_1.childImageSharp.fluid
                    }
                    alt=""
                    css={css`
                      margin: 0px auto;
                      margin-bottom: 0px;
                      width: 100%;
                    `}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="mobileBusiness">
        <MobileBusiness />
      </div>
    </div>
  )
}
